import { useStaticQuery, graphql } from "gatsby";
export const useFaqStrapiId = (id) => {
  const data = useStaticQuery(graphql`
    query FaqStrapiIdQuery {
      allStrapiFaq {
        nodes {
          id
          strapi_id
          Question
          Reponse
        }
      }
    }
  `);

  return data?.allStrapiFaq?.nodes?.find(faq => faq?.strapi_id === +id);
};
