import React, { useState } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";

import BlocImage from "../../components/dynamicZone/blocImage/blocImage";
import Faq from "../../components/faq/faq";
import HeaderLight from "../../components/header/headerLight/headerLight";
import Pagination from "../../components/pagination/pagination";
import SearchBar from "../../components/searchBar/searchBar";

import "./style.scss";

import imgBlocImage from "../../images/faq/photo.webp";
import {useFaqStrapiId} from "../../hooks/useFaqStrapiId";

const FaqsPage = ({ data, location, pageContext }) => {
  const { currentPage, numPages } = pageContext;
 
  const strapiFaqs = data.allStrapiFaq.nodes;
  const [faqs, setFaqs] = useState(strapiFaqs);

  const questionId = new URLSearchParams(location.search).get("id");
  let searchedQuestion = null;
  if (questionId) {
    const questionFound = useFaqStrapiId(+questionId);
    if (questionFound) {
      searchedQuestion = questionFound;
    }
  }

  const stateFaq = data.allStrapiFaq.nodes;

  const searchFaq = (input) => {
    if(input.target.value === ""){
      setFaqs(stateFaq)
      return;
    }
    const tempFaq = stateFaq.filter((faq)=> {
      return faq.Question.toLowerCase().includes(input.target.value.toLowerCase())
    });
    setFaqs(tempFaq)
  }

  return (
    <>
      <Helmet>
        <body className="faqs" />
      </Helmet>
      <Layout>
        <SEO title="FAQ" description="Définition, expertise, sinistre… Vous avez les questions ? Nous avons les réponses !"/>
        <HeaderLight
          title={
            <>
              <strong>Vous avez les <br></br>questions&nbsp;?</strong> <br></br>Nous avons les réponses&nbsp;!
            </>
          }
          location={location}
          crumbLabel="FAQ"
        />
        <SearchBar onChange={searchFaq} />

        { searchedQuestion != null ? (
          <>
          <Faq question={searchedQuestion.Question} number={searchedQuestion.id} isChecked={true}>
            <ReactMarkdown
              className="content-inner"
              rehypePlugins={[rehypeRaw]}
              remarkPlugins={[remarkGfm]}
              children={searchedQuestion.Reponse} 
            />
          </Faq>
          </>
        ) : (
          <>
          <ListFaq faqs={faqs.slice(0, 11)} />

          <BlocImage
            title="<strong>Vous tendre la main, c’est d’abord tendre l’oreille.</strong>"
            text="Être accompagné par un assureur que vous connaissez et qui vous connaît, ça fait toute la différence."
            btnText="Notre vision"
            imagePath={imgBlocImage}
            btnUrl="/nos-valeurs"
          />

          <ListFaq faqs={faqs.slice(11)} />
          <div className="u-mt-big">
            <Pagination
              location={location.href}
              numPage={numPages}
              currentPage={currentPage}
            />
          </div>
          </>
        )}

      </Layout>
    </>
  );
};

const ListFaq = (faqs) => {
  return (
    <section className="listFaq">
      {faqs.faqs.map((faq) => {
        return (
          <Faq question={faq.Question} number={faq.id} key={faq.id}>
            <ReactMarkdown
              className="content-inner"
              rehypePlugins={[rehypeRaw]}
              remarkPlugins={[remarkGfm]}
              children={faq.Reponse} 
            />
          </Faq>
        );
      })}
    </section>
  );
}

export const query = graphql`
  query FaqPageQuery($skip: Int!, $limit: Int!) {
    allStrapiFaq(limit: $limit, skip: $skip) {
      nodes {
        id
        strapi_id
        Question
        Reponse
      }
    }
  }
`;

export default FaqsPage;
